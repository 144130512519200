/* Ajusta os inputs do formulário*/
.custom-form-field {
  .mat-form-field {
    line-height: inherit !important;
    width: 100% !important;
  }
}

/* Adiciona quebra de linha do checkbox */
.custom-form-checkbox-line {
  padding: 5px;

  .mat-checkbox-layout {
    display: block;
    white-space: normal;
  }
}

@media screen and (min-width: 600px) {
  .custom-form-field .mat-form-field {
    padding-right: 10px !important;
  }
}
