@use '@angular/material' as mat;
@import './../_base/typography';

.d-mobile {
	display: none;
}
.d-desk {
	display: block;
}

html, body{
  font-family: Roboto;
  font-size: 14px;
  height: 100%;
  min-height: 100%;
}

// EVITANDO QUE O BOOTSTRAP QUEBRE OUTROS LOCAIS 
.container{
  padding-right: 0;
  padding-left: 0;
  max-width: none;
}

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.333337em;
}

/* Ajuste Content */
.content-wrapper {
  min-height: calc(100vh - 50px) !important;
}

.box-content {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 50px) !important;
}

.margin-right {
  margin-right: 15px;
}

.select-tarvos-dark {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  color: #ffffff !important;
  height: 30px !important;

  option {
    background-color: #ffffff !important;
    color: #000000 !important;
  }
}

.padding-right-5 {
  padding-right: 5px !important;
}

/* Cards */
.box-tarvos-light {
  border-top-color: $secondary-color !important;
  padding-left: 0px !important;
}

.box-body {
  overflow-y: auto !important;
}

.box-header {
  .box-title {
    display: block;
    width: 50%;
    float: left;
    padding-right: 15px;
  }

  .box-tools {
    display: block;
    width: 50%;
    float: right;
    max-width: 250px;
  }
}
/* Formulários */

.form-group {
  margin-bottom: 22px !important;
}

/* Calendário */
.cdk-overlay-container {
  z-index: 99999 !important;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}

.modal-dialog {
  height: 90%;
  width: 90%;
  display: flex;
  align-items: center;
}

.modal-content {
  margin: 0 auto;
  width: 100%;
}

/* Popover */
.popover-content {
  width: 200px !important;
}

.modal-footer > .pull-left {
  color: #000000 !important;
}

.modal-title {
  font-weight: 500;
  display: inline-block !important;
  width: 90% !important;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  background-color: $primary-color !important;
  color: #ffffff !important;
}

/* google maps info window */

app-google-maps-draw-marker {
  .gm-ui-hover-effect {
    display: none !important;
  }

  .gm-style .gm-style-iw-c {
    padding: 5px !important;
    padding: 0px !important;
    background-color: #ffffff;
    border-radius: 0px;
    box-shadow: none;
  }

  .gm-style .gm-style-iw-d {
    overflow: auto !important;
  }

  .gm-style .gm-style-iw-t::after {
    width: 9px;
    height: 9px;
    background-color: #dd4b39 !important;
    border-color: #d73925 !important;
    z-index: -1;
    box-shadow: none;
  }
}

/* Select + input */
.input-group {
  width: 100% !important;
}

.input-group-addon,
.input-group-btn {
  width: 200px !important;
}

.margin-right {
  margin-right: 15px !important;
}

.menu-widgets {
  /**
css menu
*/
  .nav-tabs-custom > .tab-content {
    padding: 0px !important;
  }

  .content {
    padding-left: 0px;
    padding-right: 0px;
  }

  .heat-map-title {
    width: 90% !important;
    padding-bottom: 15px !important;
  }

  .no-padding {
    padding: 0px !important;
  }

  .font-normal {
    font-weight: normal !important;
  }

  .box {
    margin: 0px;
    width: auto;
    border-radius: 0px !important;
    border-top: none !important;
  }

  .content {
    min-height: 190px !important;
  }

  .control-filter-map-close {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 0px !important;
    min-height: 100%;
    height: 100% !important;
    width: 0px;
    z-index: 110;
    transition: width 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out,
      -webkit-transform 0.3s ease-in-out;
    background: #ffffff;

    .box-body {
      transition: width 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out, width 0.3s ease-in-out,
        -webkit-transform 0.3s ease-in-out;
      overflow: hidden !important;
      height: 100%;
      float: left;
      width: 0px;
      visibility: hidden;

      h2 {
        margin-top: 0px !important;
      }
    }
  }

  .control-filter-map {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    width: 270px !important;
    z-index: 110;

    .box-body {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      visibility: visible !important;
      width: 270px;
    }
  }

  .menu-box-shadow {
    box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.12), 2px 1px 2px rgba(0, 0, 0, 0.24);
  }

  .box-body {
    min-height: 300px !important;
  }
}

.mat-expansion-panel-header {
  border-radius: initial !important;
}

.backdrop,
.spinner-three-bounce {
  z-index: 200 !important;
}

.form-control[disabled] {
  opacity: 0.6 !important;
}

@media screen and (max-width: 767px) {
  .content-wrapper,
  .box-content {
    min-height: calc(100vh - 100px) !important;
  }

  .content-list {
    max-height: calc(100vh - 206px) !important;
  }
}

/*
    Novos ajustes ao implementar o angular material
                                                    */

/* Table */
.material-content {
  min-height: 250px;
  margin: 0px 15px 15px;

  .mat-card-content {
    height: calc(100% - 121px) !important;
    margin-bottom: 0px !important;
    overflow: auto !important;
  }

  .mat-card {
    height: calc(100vh - 185px) !important;
  }
}

.padding {
  padding: 15px;
}

.material-row-height {
  height: 35px !important;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(224, 224, 224);
}

tr.material-detail-row {
  height: 0 !important;
}

.margin-left-auto {
  margin-left: auto;
}

.mat-card-header-text {
  margin: 0px !important;
}

.mat-card-header .mat-card-title {
  margin: 12px 0px !important;
}

td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type,
th.mat-header-cell:first-of-type,
td.mat-cell:last-of-type {
  padding-left: 12px !important;
  padding-right: 8px !important;
}

.material-table-column-actions {
  float: right !important;
  display: flex !important;
  border: none !important;
}

.mat-expansion-panel-body {
  padding: 0px 0px !important;
}

.cdk-global-scrollblock {
  overflow: hidden !important;
}

.mat-dialog-actions {
  &[align='space-between'] {
    justify-content: space-between !important;
  }
}

.map-container-no-padding {
  .mat-figure {
    display: block !important;
    margin: 0px 0px !important;
  }
}

.main-header .navbar {
  max-height: 50px;
}

/* Ajuste no panel no sidenav */

.sidenav {
  .mat-expansion-indicator::after {
    color: rgba($color: #ffffff, $alpha: 0.54);
  }

  .mat-expansion-panel.active-link {
    .menu-header {
      .mat-expansion-indicator::after {
        color: #000000 !important;
      }
    }
  }

  .mat-expansion-panel.active-link-sub-menu {
    .sub-menu-header {
      .mat-expansion-indicator::after {
        color: $secondary-color !important;
      }
    }
  }
}

/* Ajuste no botão de exportar dos gráficos */
.amcharts-amexport-menu-level-0 {
  margin-right: 50px !important;
}

/* Adiciona '...' no final da segunda linha */
.material-text-two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-horizontal-content-container {
  padding: 0 0px 24px 0px !important;
}
