@media (min-width: 768px) and (max-width: 1024px) {

  .card-Map {
    height: 50vh;
    margin-bottom: 0px;
    agm-map{
      height: 50vh;
    }
  }

  .charts-infos{
    .charts-infos-container{
        overflow-y: initial !important;
    }
  }

  .card-chart{
    min-width: 31vw; 
  }
  .card{
    min-width: 31vw; 
  }
  .card-container{
    height: 20vh; 
  }

  .button-occurrence{
    width: 140px; 
    height: 110px; 
  
    .content{
      font-size: 16px; 
    }
    .icon{
      height: 32px; 
      width: 32px
    }
  }

  .navigation-footer-map {
    margin-left: -12px;
    padding: 10px !important;
    width: 100% !important;
    position: absolute !important;
  }
  .legend-container{
    .legend-content-box{
      bottom: auto !important;
      z-index: 9999;
      box-shadow: 0px 11px 30px 0px rgba(0, 0, 0, 0.6);
    }
  }
}