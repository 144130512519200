@import './../_abstracts/variables';

/* configuração para a janela de informação do google mapas*/
.si-wrapper-top {
  margin-top: -10px !important;
}

.si-content-wrapper {
  padding: 10px 30px !important;
}

/* Alteração para a tela de monitoramento geral*/
.material-info-window {
  .si-content-wrapper {
    padding: 0px !important;
  }

  .si-content {
    min-width: 270px !important;
    min-height: 270px !important;
  }

  @media screen and (min-width: 426px) {
    .si-content {
      min-width: 375px !important;
      min-height: 350px !important;
      overflow-x: hidden;
    }
  }

  @media screen and (min-width: 1440px) {
    .si-content {
      min-width: 525px !important;
      min-height: 350px !important;
      overflow-x: hidden;
    }
  }
}

/* Alteração para o componente draw marker*/
.material-info-window-marker {
  .si-frame {
    padding: 0px !important;
  }
}
/* Adicionar o nome do lado do ponto */
.material-marker-name {
  margin-top: -6px !important;
  margin-left: 6px !important;

  .si-content-wrapper {
    background-color: rgba($color: #000000, $alpha: 0.5);
    border: none !important;
    color: #ffffff;
    padding: 4px !important;
    font-size: 11px;
  }

  .si-pointer-border-top,
  .si-pointer-border-right,
  .si-pointer-border-left,
  .si-pointer-border-bottom,
  .si-shadow-inner-pointer-right {
    display: none;
  }
}
