@import '~ngx-toastr/toastr.css';
@import './../_abstracts/variables';

/* Customizando o componente ngx-toastr */

.toast-container .ngx-toastr {
  box-shadow: none !important;
  border-radius: 0px !important;
}

.toast-close-button {
  font-size: 24px !important;
  text-shadow: none !important;
}

.title-class {
  font-weight: bold !important;
  font-size: 16px !important;
}

.message-class {
  font-weight: normal !important;
  font-size: 14px !important;
}

.toast-info {
  background: url('./../../assets/icons/info.svg') !important;
  background-color: $info-color !important;
}

.toast-error {
  background-image: url('./../../assets/icons/error.svg') !important;
  background-color: $error-color !important;
}

.toast-success {
  background-image: url('./../../assets/icons/success.svg') !important;
  background-color: $success-color !important;
}

.toast-warning {
  background-image: url('./../../assets/icons/warning.svg') !important;
  background-color: $warning-color !important;
}
