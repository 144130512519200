.material-dialog-panel {
  max-width: 100vw !important;
  width: 100vw !important;
  min-height: 100vh !important;
  height: 100vh !important;

  .mat-dialog-container {
    overflow: hidden !important;
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }
}

mat-dialog-container{
  position: relative;
  h1{
    color: $report-secundary-color;
    font-weight: bold !important;
  }
  .btn-close{
    right: 20px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    background-color: $report-secundary-color;
    opacity: 1;
    background-image: none;
    color: $white;
    text-align: center !important;
    i{
      display: block;
      font-size: 18px;
    }
    &:hover{
      color: $white !important;
    }
  }
}

@media (min-width: 600px) {
  .material-dialog-panel {
    max-width: 80vw !important;
    min-height: auto !important;
    width: auto !important;
    height: auto !important;

    .mat-dialog-container {
      padding-top: 24px !important;
      padding-bottom: 24px !important;
    }
  }

  .material-dialog-panel-500 {
    width: 500px !important;
  }

  .material-dialog-panel-600 {
    width: 600px !important;
  }
  .material-dialog-panel-800 {
    width: 800px !important;
  }
  .material-dialog-panel-1000 {
    width: 1000px !important;
  }
  .material-dialog-panel-1200 {
    width: 1200px !important;
  }
}

.material-dialog-toolbar {
  flex-direction: row !important;
  box-sizing: border-box !important;
  display: flex !important;
  place-content: center flex-start !important;
  align-items: center !important;
  position: fixed !important;
  height: 56px !important;
  right: 0px !important;
  left: 0px !important;
  top: 0px !important;
  z-index: 99999 !important;

  .title {
    padding-left: 16px !important;
  }
}

.material-dialog-content {
  max-height: calc(100vh - 56px - 56px) !important;
  height: 100% !important;
}

.material-dialog-actions {
  flex-direction: row !important;
  box-sizing: border-box !important;
  display: flex !important;
  place-content: center flex-start !important;
  align-items: center !important;
  position: fixed !important;
  height: 56px !important;
  right: 0px !important;
  left: 0px !important;
  bottom: 24px !important;
  padding: 0px 24px !important;
}

@media (min-width: 600px) {
  .material-dialog-toolbar {
    padding: 0 !important;
    background-color: transparent !important;
    position: relative !important;
    place-content: space-between !important;
    flex-direction: row-reverse !important;

    .title {
      padding-left: 0px !important;
    }
  }

  .material-dialog-actions {
    position: initial !important;
    padding: 0px !important;
  }
}
