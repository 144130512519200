@use '@angular/material' as mat;
@import './../_base/typography';

/* Ajuste no body da aplicação */
body {
  font-family: mat.font-family($custom-typography) !important;
  margin: 0 !important;
}

/* Remover o sublinhado dos links */
a {
  text-decoration: none !important;
}

/* Ajustando o tamanho das tabelas */
table {
  width: 100% !important;
}

/* Desabilita o botão do input number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/*  Preenchimentos genéricos  */

/* Preenchimentos 15px */
.reset-padding-15 {
  padding: 15px !important;
}

/* Preenchimentos top 15px */
.reset-padding-top-15 {
  padding-top: 15px !important;
}

/* Sem preenchimentos 15px */
.reset-no-padding-15 {
  padding: 0px !important;
}

/* Posicionamentos genéricos*/

/* Esquerda */
.reset-align-left {
  text-align: left !important;
}

/* Centralizado */
.reset-align-center {
  text-align: center !important;
}

/* Direita */
.reset-align-right {
  text-align: right !important;
}

/* Deixa a opacidade das modais um pouco mais baixa devido ao sombreamento das sombras do card*/
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.75 !important;
}
