@use '@angular/material' as mat;
/* Adicionar cores ao button toggle  */
@mixin buttonToggleColor($theme) {
  $primary: map-get($theme, primary);

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 36px !important;
  }

  .mat-button-toggle-checked {
    background-color: mat.get-color-from-palette($primary) !important;
    color: mat.get-color-from-palette($primary, default-contrast) !important;
  }
}

/* Adicionar uma borda no card das listas */
@mixin topCardBorder($theme) {
  $primary: map-get($theme, primary);

  .material-top-card-border {
    border-top: 4px solid mat.get-color-from-palette($primary) !important;
  }
}

/* Evidenciar o link do item da tabela */
@mixin tableItemLink($theme) {
  $primary: map-get($theme, primary);

  .material-table-item-link {
    cursor: pointer !important;
    color: mat.get-color-from-palette($primary) !important;
    font-weight: bold !important;
  }
}

/* Altera as texto quando quando o item estiver selecionado */
@mixin itemSelect($theme) {
  $primary: map-get($theme, primary);

  .material-item-select {
    font-weight: bold !important;
    text-decoration: underline !important;
    color: mat.get-color-from-palette($primary) !important;
  }

  .material-item-select.mat-menu-item .mat-icon-no-color,
  .material-item-select.mat-menu-item-submenu-trigger::after {
    color: mat.get-color-from-palette($primary) !important;
  }
}

/* Customizar as cores do card ao acionar o evento hover */
@mixin defineColorsChartCard($theme, $opacity) {
  $primary: map-get($theme, primary);

  .material-card-container {
    .material-card-icon {
      opacity: $opacity;
    }
  }

  .material-card-container:hover {
    box-shadow: 0 0 0 3px mat.get-color-from-palette($primary);

    .material-card-icon {
      color: mat.get-color-from-palette($primary);
    }
  }
}
