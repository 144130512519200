/* Deixa o overflow visível no menu de info */
.material-container-drawer-menu {
  .mat-drawer-inner-container {
    overflow-y: scroll !important;
  }

  .mat-tab-header {
    position: absolute !important;
    z-index: 9 !important;
    width: calc(100% - 8px);

    .mat-tab-header-pagination-after {
      margin-right: 50px;
    }
  }

  .mat-tab-body-wrapper {
    padding-top: 50px;
  }
}
