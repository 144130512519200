@media only screen and (max-width: 768px) {
    
    .d-mobile {
        display: block;
    }
    .d-desk {
        display: none;
    }

    .material-content {
        min-height: 250px;
    
        .mat-card-content {
          height: calc(100% - 130px) !important;
          overflow: auto !important;
        }
    
        .mat-card {
          height: calc(100vh - 200px) !important;
        }
    }

    // RELATÓRIO INTERATIVO /////////////////////
    header{
        min-height: auto !important;
        .toolbar-container {
            .material-toolbar {
                padding: 0 !important;
                .content-title-toolbar{
                    padding-top: 5px !important;
                    .icon{
                        margin-right: 10px !important;
                        width: 30px !important;
                        height: 30px !important;
                        img{
                            width: 21px !important;
                        }
                    }
                    .title{
                        font-size: 15px !important;
                        margin-bottom: 0 !important;
                        line-height: 15px !important;
                    }
                    .date{
                        font-size: 12px !important;
                        font-weight: normal !important;
                    }
                }
                .logo{
                    width: 60px !important;
                }
            }
        }
    }
    .charts-infos{
        padding-right: 10px !important;
        padding-top: 10px;
        .tabs{
            margin-bottom: 15px !important;
        }
        .charts-infos-container{
            overflow-y: initial !important;
            padding: 0 !important;
            .content-infos{
                padding-bottom: 10px !important;
            }
            .average-mad{
                .average{
                    width: 100% !important;
                    padding: 4px 0 !important;
                }
                .legend{
                    width: 100% !important;
                    .legend-mad{
                        text-align: center;
                        margin-top: 5px;
                        margin-bottom: 0;
                        li{
                            display: inline-block;
                            margin-right: 15px;
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .card-chart{
                min-width: 100% !important;
                .card-content-chart{
                    padding-right: 0 !important;
                    font-size: 10px !important;
                    &::after{
                        height: 28px !important;
                        bottom: 0 !important;
                        left: -12px;
                    }
                    app-evolution-chart, app-media-mad-chart, app-weather-chart{
                        margin-left: -20px;
                        margin-right: -10px;
                    }
                    app-media-mad-chart{
                        height: 250px !important;
                    }
                    .amcharts-amexport-menu-level-0 {
                        margin-right: 10px !important;
                    }
                }
                &.chart-media-mad{
                    min-height: 250px !important;
                }
            }
            .occurrence-filter-evolution{
                .button-occurrence{
                  width: 45px !important; 
                  height: 45px !important; 
                  .content{
                    font-size: 8px;
                  }
                }
              }
        }
        .cards-farm-infos{
            .card{
                width: 100% !important;
                max-width: 100% !important;
                min-width: 100% !important;
            }
        }
    }

    .other-informations{
        .box{    
          &.box-fields{
            .fields{
                li{
                    width: 50% !important;
                    margin-bottom: 20px;
                    border-bottom: 1px solid #666;
                    &:last-child{
                        margin-bottom: 0;
                        border-bottom: 0;
                    }
                }
              .traps{
                padding-bottom: 15px !important;
                li{
                    width: calc(100%/2) !important;
                    margin-bottom: 0;
                    border-bottom: 0;
                }
              }
    
            }
          }
        }
    }

    .tabs .farm, .field{
        margin-right: 5px !important;
      }

    .noOccurrence{
        height: 40vh !important;
    }

    .card-Map {
        height: 40vh;
        agm-map{
          height: 50vh;
        }
    }
    .material-legend {
        margin: 5px;
        margin-bottom: 10px;
    }
    .navigation-footer-map {
        margin-left: -12px;
        padding: 10px !important;
        width: 100% !important;
        position: absolute !important;
    }

    // MODAL
    .mat-dialog-container {
        padding: 10px !important;
        .btn-close {
            right: 10px;
        }
        .mat-dialog-content {
            margin: 0 -10px !important;
            padding: 0 10px 10px 10px !important;
            .contato{
                text-align: center !important;
                button{
                    margin-left: 0 !important;
                    margin-top: 5px;
                }
            }
        }
    }
    .farm-details-areas{
        line-height: 12px;
        .item {
            width: 100% !important;
            margin: 0 0 8px 0 !important;
            .btn{
                font-size: 0.7rem;
            }
            h4{
                font-size: 14px !important;
            }
        }
    }

    .legend-container{
        .legend-content-box{
            position: fixed !important;
            width: calc(100% - 40px) !important;
            left: 20px !important;
            top: 105px !important;
            z-index: 9999;
            height: max-content;
            box-shadow: 0px 11px 30px 0px rgba(0, 0, 0, 0.6);
        }
    }
}