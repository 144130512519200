@import './../_abstracts/variables';

.custom-button-close {
  position: relative !important;
  bottom: 30px !important;
  left: 24px !important;

  .las {
    font-size: 18px !important;
  }
}

.custom-button-help {
  width: 20px !important;
  height: 20px !important;
  line-height: 24px !important;

  .mat-button-wrapper {
    padding: 0px !important;
  }
}

.custom-tooltip-help {
  font-size: 14px !important;
}

.btn {
  padding: 0.2rem 1.5rem;
  font-size: 0.9rem;
  font-weight: 700;
  border-radius: 0.35rem;
  transition: all 0.3s ease;
  &.btn-primary {
    background: $report-secundary-color;
    border: 0.125rem solid $report-secundary-color;
    color: $white;
    padding: 0.35rem 1.5rem;
    &:hover {
      background: $primary-dark-color;
      color: $white;
    }
  }

  &.btn-secondary {
    background: transparent;
    border: 0.125rem solid $report-secundary-color;
    color: $report-secundary-color;
    padding: 0.35rem 1.5rem;
    &:hover {
      background: $report-secundary-color;
      color: $white;
    }
  }

  &.btn-tertiary {
    background: transparent;
    border: 0.125rem solid $report-secundary-color;
    color: $report-secundary-color;
    padding: 0.35rem 1.5rem;
    &:hover {
      background: $report-secundary-color;
      color: $white;
    }
  }

  &.btn-quartiary {
    font-size: 0.7rem;
    color: $report-secundary-color;
    padding: 0.5rem;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &:disabled {
    border: 0 !important;
    cursor: not-allowed;
  }
}