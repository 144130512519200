@use '@angular/material' as mat;
$default-primary: mat.define-palette($tarvos-default);
$default-accent: mat.define-palette($tarvos-default, A200, A100, A400);
$default-warn: mat.define-palette($tarvos-warn);
$default-theme: mat.define-light-theme(
  (
    color: (
      primary: $default-primary,
      accent: $default-accent,
      warn: $default-warn,
      test: $default-warn,
    ),
  )
);

@include mat.all-component-themes($default-theme);

/* Adicionar o tema clero para o botão toggle */
@include buttonToggleColor($default-theme);

/* Adicionar uma borda no card das listas */
@include topCardBorder($default-theme);

/* Evidenciar o link do item da tabela */
@include tableItemLink($default-theme);

/* Altera as texto quando quando o item estiver selecionado */
@include itemSelect($default-theme);

/* Customizar as cores do card ao acionar o evento hover */
@include defineColorsChartCard($default-theme, 0.2);

/* Define a cor do background */
.tm-body {
  height: 100%;
  //background: #fafafa;
}

/* Cor do menu */
.menu-sidenav {
  background-color: $secondary-dark-color;
}

/* Textos com cor cinza*/
.tm-color-gray {
  color: rgba(0, 0, 0, 0.54);
}

/* Cor de fundo primaria */
.tm-background-primary {
  background-color: mat.get-color-from-palette($default-primary) !important;
  color: mat.get-color-from-palette($default-primary, default-contrast) !important;
}

/* Adiciona um cor escura no header da sidenav dos gráficos */
.tm-background-header-custom .mat-tab-header {
  background-color: #fafafa !important;
}

.tm-dialog-toolbar-color {
  color: white !important;
}

@media (min-width: 600px) {
  .tm-dialog-toolbar-color {
    color: black !important;
  }
}

/* Customizar o scrollbar */
* {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: lightgray;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: $report-secundary-color;
  }
}
