/*
* Cores da aplicação
*/
$primary-color: #00394e;
$secondary-color: #ecb842;
$white: #fff;

/*
* Cores do Report / Relatório Interativo
*/
$report-primary-color: #00394e;
$report-secundary-color: #005976;
/*
* Textos da aplicação
*/
$light-theme-primary-color: rgba(
  $color: #000000,
  $alpha: 0.87,
);

$light-theme-secondary-color: rgba(
  $color: #000000,
  $alpha: 0.54,
);

$light-theme-disabled-color: rgba(
  $color: #000000,
  $alpha: 0.38,
);

$dark-theme-primary-color: rgba(
  $color: #ffffff,
  $alpha: 1,
);

$dark-theme-secondary-color: rgba(
  $color: #ffffff,
  $alpha: 0.7,
);

$dark-theme-disabled-color: rgba(
  $color: #ffffff,
  $alpha: 0.3,
);

/*
* Cores das notificações
*/
$notification-light-color: #000000;
$notification-info-color: #2196f3;
$notification-success-color: #4caf50;
$notification-danger-color: #f44336;
$notification-warning-color: #ff9800;

/*
* Menu
*/
$primary-dark-color: #00394e;
$secondary-dark-color: #004962;

/*
* Componente toastr
*/
$info-color: #1377d5;
$success-color: #4eb862;
$warning-color: #fbb13c;
$error-color: #f44336;

/*
*  Nível de bateria e fator de enchimento
*/
$high: #4eb862;
$medium: #fbb13c;
$low: #f44336;

/*
*  Nível de MAD
*/
$lowMad: #4eb862;
$mediumMad: #fbb13c;
$highMad: #f44336;

/*
* Cores de heatmap / legend
*/
$legend-red: #dd1d21;
$legend-yellow: #fbce07;
$legend-mid-green: #5cab2a;
$legend-light-white: #fafafa;
$legend-very-dark-grey: #000000;
$legend-light-grey: #a6a6a6;

/*
* Cores ndvi
*/
$ndvi-legend-red: #e50004;
$ndvi-legend-yellow: #f5e702;
$ndvi-legend-green: #027e02;
$ndvi-legend-white: #d7d7d7;

/*
* Cores para o tema do angular material ( Colores Tarvos )
*/
$tarvos-default: (
  50: #e0e7ea,
  100: #b3c4ca,
  200: #809ca7,
  300: #4d7483,
  400: #265769,
  500: #00394e,
  600: #003347,
  700: #002c3d,
  800: #002435,
  900: #001725,
  A100: #efc35e,
  A200: #ecb842,
  A400: #e7a833,
  A700: #e4a02b,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$tarvos-dark: (
  50: #fdf6e8,
  100: #f9eac6,
  200: #f6dca1,
  300: #f2cd7b,
  400: #efc35e,
  500: #ecb842,
  600: #eab13c,
  700: #e7a833,
  800: #e4a02b,
  900: #df911d,
  A100: #265769,
  A200: #00394e,
  A400: #002c3d,
  A700: #002435,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$tarvos-warn: (
  50: #fee8e7,
  100: #fcc7c3,
  200: #faa19b,
  300: #f77b72,
  400: #f65f54,
  500: #f44336,
  600: #f33d30,
  700: #f13429,
  800: #ef2c22,
  900: #ec1e16,
  A100: #ffffff,
  A200: #ffe9e9,
  A400: #ffb8b6,
  A700: #ff9f9c,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
