.material-weather-forecast-container {
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(111, 111, 111, 0.55);
  padding: 5px;
  border-radius: 4%;

  min-height: 161px;
  min-width: 105px;
  max-width: 105px;

  .material-day-container {
    padding-bottom: 15px;
    .las {
      font-size: 40px;
    }

    img {
      width: 60px;
    }

    span {
      text-align: center;
      font-size: 12px;
    }
  }

  .material-info-container {
    min-height: 25px;
    min-width: 100px;
  }

  .material-icon {
    font-size: 18px;
  }

  .material-icon:hover {
    color: #ffffff;
    font-size: 20px !important;
  }
}

.material-weather-forecast-tooltip {
  font-size: 12px !important;
}
