@use '@angular/material' as mat;
$dark-primary: mat.define-palette($tarvos-dark);
$dark-accent: mat.define-palette($tarvos-dark, A200, A100, A400);
$dark-warn: mat.define-palette($tarvos-warn);
$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $dark-primary,
      accent: $dark-accent,
      warn: $dark-warn,
      test: $default-warn,
    ),
  )
);

.dark-theme {
  @include mat.all-component-colors($dark-theme);

  /* Adicionar o tema escuro para o botão toggle */
  @include buttonToggleColor($dark-theme);

  /* Adicionar uma borda no card das listas */
  @include topCardBorder($dark-theme);

  /* Evidenciar o link do item da tabela */
  @include tableItemLink($dark-theme);

  /* Altera as texto quando quando o item estiver selecionado */
  @include itemSelect($dark-theme);

  /* Customizar as cores do card ao acionar o evento hover */
  @include defineColorsChartCard($dark-theme, 0.1);

  /* Define a cor do background */
  .tm-body {
    color: white;
    background: #303030;
  }

  /* Textos com cor cinza*/
  .tm-color-gray {
    color: hsla(0, 0%, 100%, 0.7);
  }

  /* Adiciona um cor escura no sidenav dos gráficos */
  .tm-background-custom {
    background-color: #303030 !important;
  }

  /* Adiciona um cor escura no header da sidenav dos gráficos */
  .tm-background-header-custom .mat-tab-header {
    background-color: #303030 !important;
  }

  /* Cor de fundo primaria */
  .tm-background-primary {
    background-color: mat.get-color-from-palette($dark-primary) !important;
    color: mat.get-color-from-palette($dark-primary, default-contrast) !important;
  }

  .tm-dialog-toolbar-color {
    color: black !important;
  }

  @media (min-width: 600px) {
    .tm-dialog-toolbar-color {
      color: white !important;
    }
  }

  /* Customizar o scrollbar */
  * {
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: transparent;
    }

    ::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: darkgrey;
    }
  }
}
