.material-timeline-dialog-content {
  max-height: none !important;
  margin: 0 !important;
  padding: 0px !important;

  .material-custom-step-timeline {
    .mat-step-icon {
      height: 12px !important;
      width: 12px !important;
    }

    .mat-step-icon-content {
      display: none !important;
    }

    .mat-vertical-content-container {
      margin-left: 29px !important;
    }

    .mat-step-label {
      font-size: 14px !important;
      font-weight: 400 !important;
    }

    .mat-vertical-stepper-content {
      visibility: visible !important;
      height: 100% !important;
    }

    .material-timeline-date {
      position: absolute !important;
      top: 45px !important;
      left: 47px !important;
      font-size: 10px !important;
    }

    .material-timeline-container {
      .row {
        padding: 15px !important;
      }

      label {
        padding: 7px 0px !important;
        font-weight: bold !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        width: 100% !important;

        span {
          font-weight: 400 !important;
        }
      }
    }
  }
}
